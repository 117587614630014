<template>
  <main>
    <section class="bg-light cameras">
      <div class="pt-5 container secondary">
        <div class="row">
        
          <div class="col-md-6 col-lg-3 bg-white py-2 px-2 tree-view">
            <ul v-for="first in firstSubGroup" :key="first.name"  >
              <li>{{first.name}} </li>
                <ul class="camera-list" v-if="first.subgroups.length !== 0" v-for="second in first.subgroups" :key="second.name">
                <li>{{second.name}}</li>
                <ul>
                  <draggable class="list-group dragArea w-full" :list="second.cameras" :group="{name: 'cameras', pull: 'clone', put: 'false'}" :sort="false" itemKey="second.cameras.camNo" :move="handleMove" @end="handleFinish">
                    <template #item="{element}">
                      <li class="list-group-item camera-list-item cursor-move" :key="element.camNo">
                        {{ element.camNo == '-1' ? '' : element.camNo + ' - '  }}{{ element.name }}
                      </li>
                    </template> 
                  </draggable>
                </ul>
              </ul>

              <ul>
                <draggable class="list-group dragArea" :list="first.cameras" :group="{name: 'cameras', pull: 'clone', put: 'false'}" :sort="false"  itemKey="first.cameras.camNo" :move="handleMove" @end="handleFinish">
                  <template #item="{element}">
                    <li class="list-group-item cursor-move camera-list-item" :key="element.camNo">{{ element.camNo == '-1' ? '' : element.camNo + ' - '  }}{{ element.name }}</li>
                  </template>                
                </draggable>
              </ul> 
            </ul>
          </div>

          

          <div class="col-md-6 col-lg-9 mb-5 camera-view">
          
            <div class="row">
            

           
              
              <!-- ITERATES OVER EACH LAYOUT IN LAYOUTS ARRAY-->
              <div class="col-md-6 mt-4" v-for="layout in layouts" :key="layout.id">
                <!-- TEMPLATE FOR EACH LAYOUT-->
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">{{layout.layoutName}}</h3>
                    
                  </div>

                  <div class="card-body">

                    <draggable class="list-group draggable-list row dragArea"  :data-list="layout.id" :class="mapGridToClass(layout.layout)" v-model="layout.cameras" :group="{name:'cameras'}" itemKey="layout.cameras.camNo" :move="handleMove" @end="handleFinish">

                    
                      <template #item="{element, index}">

                        <div class="list-group-item cursor-move" :class="'div' + (index + 1)" data-toggle="tooltip" data-placement="right" :title="element.camNo +' - '+ element.name" :key="element.name">
                          {{ element.camNo == '-1' ? '' : element.camNo + ' - '  }}{{ element.name }} 
                        </div>

                      </template>

                    </draggable>
                    
                  </div>

                  <div class="card-footer">
                  <!-- <p>{{JSON.stringify(layout.cameras)}}</p> -->
                  <div class="dropdown justify-content-start">
                    <button class="btn btn-primary dropdown-toggle" type="button" id="gridDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    Layout
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <div class="container grid-options-container">
                        
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1.png'"  role="button" @click="changeLength('1', layout.id)" alt="1x1 grid" title="1x1" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/2x2.png'"  role="button" @click="changeLength('2x2', layout.id)" alt="2x2 grid" title="2x2" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/3x3.png'"  role="button" @click="changeLength('3x3', layout.id)" alt="3x3 grid" title="3x3" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/4x4.png'"  role="button" @click="changeLength('4x4', layout.id)" alt="4x4 grid" title="4x4" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/5x5.png'"  role="button" @click="changeLength('5x5', layout.id)" alt="5x5 grid" title="5x5" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1+5.png'"  role="button" @click="changeLength('1+5', layout.id)" alt="1 large + 5" title="1 large + 5" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1+7.png'"  role="button" @click="changeLength('1+7', layout.id)" alt="1 large + 7" title="1 large + 7" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1+9.png'"  role="button" @click="changeLength('1+9', layout.id)" alt="1 large + 9" title="1 large + 9" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1+12_CT.png'" role="button" @click="changeLength('1+12_CT', layout.id)" alt="1 large center + 12" title="1 large center + 12" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1+16_CT.png'"  role="button" @click="changeLength('1+16_CT', layout.id)" alt="1 large center + 16" title="1 large center + 16" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/3x2.png'"  role="button" @click="changeLength('3x2', layout.id)" alt="3x2" title="3x2" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/4x3.png'"  role="button" @click="changeLength('4x3', layout.id)" alt="4x3 grid" title="4x3" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/6x4.png'"  role="button" @click="changeLength('6x4', layout.id)" alt="6x4 grid" title="6x4" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1+8_CT.png'"  role="button" @click="changeLength('1+8_CT', layout.id)" alt="Center large + 8 surrounding" title="Center large + 8" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1+8_TL.png'"  role="button" @click="changeLength('1+8_TL', layout.id)" alt="Top left large + 8 surrounding" title="Top left large + 8" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1W+3.png'"  role="button" @click="changeLength('1W+3', layout.id)" alt="1 wide at top + 3 small below" title="1 wide + 3" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1W+6.png'"  role="button" @click="changeLength('1W+6', layout.id)" alt="1 wide at top + 6 small below" title="1 wide + 6" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1W+8.png'"  role="button" @click="changeLength('1W+8', layout.id)" alt="1 wide at top + 8 small below" title="1 wide + 8" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1W+12.png'"  role="button" @click="changeLength('1W+12', layout.id)" alt="1 wide at top + 12 small below" title="1 wide + 12" width="50" height="50"/></div>
                          <div class="col gridImage"><img v-bind:src="'/img/gridIcons/1W+20.png'"  role="button" @click="changeLength('1W+20', layout.id)" alt="1 wide at top + 20 small below" title="1 wide + 20" width="50" height="50"/></div>
                        
                      </div>
                    </ul>
                  </div>
                    
                  </div>
                </div>
              </div>
              <!-- //TEMPLATE FOR EACH LAYOUT -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <p>
  <!--{{JSON.stringify(this.layouts, null, 2)}}-->
  </p>
   

  
</template>
<script>
import axios from 'axios';
import draggable from "vuedraggable";
import {GridLayout, GridItem} from "vue3-grid-layout"

export default {
  components: {
    draggable,
    GridLayout,
    GridItem
    
  },
  data() {
    return {

     
      
      webUrl: 'https://cctv.digital-panda.co.uk/api/',
      webUrl2: 'https://toasthost.co.uk/cameras/',
      webUrl3: 'http://localhost:3001/api/',

      data: {},
      firstSubGroup: {},
      animate: true,
      layouts: {},
      draggable: true,
      resizable: false,
      moveState: {"name": "-", "camNo": "-", "futureIndex": "-" , "toLayoutIndex": "-", "fromIndex":"-", "fromLayoutIndex":"-", "toName": "-", "toCamNo": "-" },
     
    };
  },
  created () {
    this.fetchCameras()
    this.fetchLayouts()
    
  },
  methods: {

    fetchCameras() {
      var endpoint = this.webUrl + 'cameras.php'
      
      axios.get(endpoint)
          .then(response => {
            this.firstSubGroup = response.data.subgroups
            this.firstSubGroup.unshift({"name":"Blank Camera","subgroups":[],"cameras":[{"camNo":-1, "name":"Blank"}]})
          }).catch(error => {
            console.log(error);
          })
    },

    fetchLayouts() {
      var endpoint = this.webUrl + 'layouts.php'
      
      axios.get(endpoint)
          .then(response => {
            //console.log(response.data.disp)
            this.layouts = response.data.displays
            
          }).catch(error => {
            console.log(error);
          })
    },
    
    changeLength(length, layoutId) {
      //check if requested length is greater than current length
      //get current layout info via layoutId
      const layoutToChange = this.layouts.find(layout => layout.id === layoutId)
      //get the number of camera slots for the requested grid size
      const newLength = this.mapGridToNumber(length);
      const oldLength = this.mapGridToNumber(layoutToChange.layout)

      if (newLength > oldLength) {
        let newBlanksArray = []
        for (let i = 1; i <= newLength - oldLength; i++) {
          newBlanksArray.push({"name":"Blank", "camNo":"-1"})
        }
        this.layouts[layoutId -1].cameras = [...this.layouts[layoutId -1].cameras, ...newBlanksArray]
        this.layouts[layoutId -1].layout = length;

      }

      if (newLength < oldLength) {
        this.layouts[layoutId -1].cameras.splice(newLength, oldLength - newLength)
        this.layouts[layoutId -1].layout = length;

      }

      if (newLength === oldLength) {
        this.layouts[layoutId -1].gridSize = length;
      }

      
      
      var endpoint = this.webUrl + 'layout/'
      axios.post(endpoint, this.layouts)
          .then(response => {
            console.log(response)
          }).catch(error => {
            console.log(error);
          })
    },

    
    handleMove(evt, originalEvt) { 
      
      // index of target layout within layouts data object
      const toLayoutIndex = evt.to.getAttribute('data-list') - 1;
      // index of from layout within layouts data object
      const fromLayoutIndex = evt.from.getAttribute('data-list') -1;
      // grid index of dragged camera
      const fromIndex = evt.draggedContext.index;
      // numer of cameras currently in target layout cameras array
      const toLayoutCurrentCamerasLength = this.layouts[toLayoutIndex].cameras.length;
      // maximum cameras allowed in target layout defined by grid type
      const toLayoutCurrentMaxSize = this.mapGridToNumber(this.layouts[toLayoutIndex].layout);

      
      
      
      if (fromLayoutIndex === -1) {
        //camera coming from camera list
        console.log('dragged camera comes from camera list')
        const futureIndex = originalEvt.target.__draggable_context.index;
        this.moveState.name = evt.draggedContext.element.name;
        this.moveState.camNo = evt.draggedContext.element.camNo;
        this.moveState.futureIndex = originalEvt.target.__draggable_context.index;
        this.moveState.toLayoutIndex = toLayoutIndex;
        return false;
      }

      if (toLayoutIndex === fromLayoutIndex) {
        //camera moving within same grid
        //set moveState parameters which are not needed to -
        this.moveState.name = evt.draggedContext.element.name;
        this.moveState.camNo = evt.draggedContext.element.camNo;
        this.moveState.futureIndex = originalEvt.target.__draggable_context.index;
        this.moveState.fromIndex = fromIndex;
        this.moveState.toLayoutIndex = toLayoutIndex;
        this.moveState.fromLayoutIndex = fromLayoutIndex;
        this.moveState.toName = this.layouts[this.moveState.toLayoutIndex].cameras[parseInt(this.moveState.futureIndex)].name;
        this.moveState.toCamNo = this.layouts[this.moveState.toLayoutIndex].cameras[parseInt(this.moveState.futureIndex)].camNo;
        console.log('camera being moved within same grid it came from')
        console.dir(moveState)
        return false;
      } 
      
      if (toLayoutIndex !== fromLayoutIndex) {
        // camera moving between grids
        //get toLayoutIndex and toLayoutFutureIndex - make this array/slot info from dragged camera
        //get fromLayoutIndex and fromLayoutGridIndex - make this array/slot blank
        console.log('camera being moved between grids')
        this.moveState.toName = '-';
        this.moveState.toCamNo = '-';
        this.moveState.name = evt.draggedContext.element.name;
        this.moveState.camNo = evt.draggedContext.element.camNo;
        this.moveState.futureIndex = originalEvt.target.__draggable_context.index;
        this.moveState.toLayoutIndex = toLayoutIndex;
        this.moveState.fromLayoutIndex = fromLayoutIndex;
        this.moveState.fromIndex = fromIndex;
        return false;
      }

    },

    handleFinish() {
      //finish list to grid move - all of the below parameters must be set to be a list to grid move.
      if (this.moveState.name !== '-' && this.moveState.camNo !== '-' && this.moveState.futureIndex !== "-" && this.moveState.toLayoutIndex !== "-" && this.moveState.fromIndex === '-' && this.moveState.fromLayoutIndex === '-') {
      this.layouts[this.moveState.toLayoutIndex].cameras[parseInt(this.moveState.futureIndex)].name = this.moveState.name;
      this.layouts[this.moveState.toLayoutIndex].cameras[parseInt(this.moveState.futureIndex)].camNo = this.moveState.camNo;
      }
      
      //finish camera move between grids - all of the below parameters must be set to be an inter grid move. 
       else if (this.moveState.name !== '-' && this.moveState.camNo !== '-' && this.moveState.futureIndex !== '-' && this.moveState.toLayoutIndex !== '-' && this.moveState.fromIndex !== '-' && this.moveState.fromLayoutIndex !== '-' && this.moveState.toName === '-' && this.moveState.toCamNo === '-') {
        console.log('camera has been moved between grids')
        this.layouts[this.moveState.toLayoutIndex].cameras[parseInt(this.moveState.futureIndex)].name = this.moveState.name;
        this.layouts[this.moveState.toLayoutIndex].cameras[parseInt(this.moveState.futureIndex)].camNo = this.moveState.camNo;
        this.layouts[this.moveState.fromLayoutIndex].cameras[parseInt(this.moveState.fromIndex)].name = 'Blank';
        this.layouts[this.moveState.fromLayoutIndex].cameras[parseInt(this.moveState.fromIndex)].camNo = '-1';
      } 
      //finish camera move within same grid - all of the below parameters must be true to be an intra grid move.
        else if (this.moveState.name !== '-' && this.moveState.camNo !== '-' && this.moveState.futureIndex !== '-' && this.moveState.toLayoutIndex !== '-' && this.moveState.fromIndex !== '-' && this.moveState.fromLayoutIndex !== '-' && this.moveState.toName !== '-' && this.moveState.toCamNo !== '-') {
          console.log('camera has moved within same grid')
          this.layouts[this.moveState.toLayoutIndex].cameras[parseInt(this.moveState.futureIndex)].name = this.moveState.name;
          this.layouts[this.moveState.toLayoutIndex].cameras[parseInt(this.moveState.futureIndex)].camNo = this.moveState.camNo;
          this.layouts[this.moveState.fromLayoutIndex].cameras[parseInt(this.moveState.fromIndex)].name = this.moveState.toName;
          this.layouts[this.moveState.fromLayoutIndex].cameras[parseInt(this.moveState.fromIndex)].camNo = this.moveState.toCamNo;
        }

      
      
      
      //reset moveState
      this.moveState.name = "";
      this.moveState.camNo = "";
      this.moveState.futureIndex = "-";
      this.moveState.toLayoutIndex = "-";
      this.moveState.fromIndex = "-";
      this.moveState.fromLayoutIndex = "-";
      this.moveState.fromName = '-';
      this.moveState.fromCamNo = '-';
      

      
      
      //submit changes to api
      var endpoint = this.webUrl + 'layout/'
      axios.post(endpoint, this.layouts)
          .then(response => {
            console.log(response)
          }).catch(error => {
            console.log(error);
          })
    },

   
    mapGridToClass(grid) {
    switch(grid) {
      case "1":
        return "parent-1"
        break;
      case "2x2":
        return "parent-2"
        break;
      case "3x3":
        return "parent-3"
        break;
      case "4x4":
        return "parent-4"
        break;
      case "5x5":
        return "parent-5"
        break;
      case "1+5":
        return "parent-6"
        break;
      case "1+7":
        return "parent-7"
        break;
      case "1+9":
        return "parent-8"
        break;
      case "1+12_CT":
        return "parent-9"
        break;
      case "1+16_CT":
        return "parent-10"
        break;
      case "3x2":
        return "parent-11"
        break; 
      case "4x3":
        return "parent-12"
        break; 
      case "6x4":
        return "parent-13"
        break;
      case "1+8_CT":
        return "parent-14"
        break;
      case "1+8_TL":
        return "parent-15"
        break; 
      case "1W+3":
        return "parent-16"
        break;
      case "1W+6":
        return "parent-17"
        break; 
      case "1W+8":
        return "parent-18"
        break;  
      case "1W+12":
        return "parent-19"
        break;
      case "1W+20":
        return "parent-20"
        break;
      
    }
   },

   mapGridToNumber(grid) {
    switch(grid) {
      case "1":
        return 1
        break;
      case "2x2":
        return 4
        break;
      case "3x3":
        return 9
        break;
      case "4x4":
        return 16
        break;
      case "5x5":
        return 25
        break;
      case "1+5":
        return 6
        break;
      case "1+7":
        return 8
        break;
      case "1+9":
        return 10
        break;
      case "1+12_CT":
        return 13
        break;
      case "1+16_CT":
        return 17
        break;
      case "3x2":
        return 6
        break; 
      case "4x3":
        return 12
        break; 
      case "6x4":
        return 24
        break;
      case "1+8_CT":
        return 9
        break;
      case "1+8_TL":
        return 9
        break; 
      case "1W+3":
        return 4
        break;
      case "1W+6":
        return 7
        break; 
      case "1W+8":
        return 9
        break;  
      case "1W+12":
        return 13
        break;
      case "1W+20":
        return 21
        break;
      
    }
   },

   
  }
};
</script>
<style scoped>

.grid-options-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr)
}

@media (min-width: 600px) {
  .grid-options-container {grid-template-columns: repeat(3, 1fr);}
}

@media (min-width: 900px) {
  .grid-options-container {grid-template-columns: repeat(4, 1fr);}
}

@media (min-width: 1200px) {
  .grid-options-container {grid-template-columns: repeat(5, 1fr);}
}




.gridImage:hover {
  transform: scale(1.1);
}

.camera-list-item {
  padding: 3px
}

.camera-list {
  padding: 3px
}


.cameras .card-body {
  height: auto;
  margin-top: -1px;
  min-height: 370px;
  display: grid;
  padding: 4px;
}

.draggable-list {
  color: rgb(160, 160, 160);
  margin: 0;
}
.draggable-list div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9ecef;
  min-height: 100px;
  transition: all .3s ease;
  margin: 0;
  border: 1px solid;
}

.list-item {
  margin: 10px;
  cursor: move;
  font-size: 18px;
  background: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
  color: rgb(160, 160, 160);
  display: inline-block;
}
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
.cursor-move {
  cursor: move;
}
.tree-view,
.camera-view {
  height: 100vh;
  overflow-y: auto;
}
.tree-view ul,
tree-view li {
  font-weight: bold;
}
.card-footer {
  text-align: right;
}
.dropdown-menu {
  right: 0;
}

.parent-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-1 .div1 { grid-area: 1 / 1 / 2 / 2; }

.parent-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-2 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-2 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-2 .div3 { grid-area: 2 / 1 / 3 / 2; }
.parent-2 .div4 { grid-area: 2 / 2 / 3 / 3; }

.parent-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-3 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-3 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-3 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-3 .div4 { grid-area: 2 / 1 / 3 / 2; }
.parent-3 .div5 { grid-area: 2 / 2 / 3 / 3; }
.parent-3 .div6 { grid-area: 2 / 3 / 3 / 4; }
.parent-3 .div7 { grid-area: 3 / 1 / 4 / 2; }
.parent-3 .div8 { grid-area: 3 / 2 / 4 / 3; }
.parent-3 .div9 { grid-area: 3 / 3 / 4 / 4; }

.parent-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-4 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-4 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-4 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-4 .div4 { grid-area: 1 / 4 / 2 / 5; }
.parent-4 .div5 { grid-area: 2 / 1 / 3 / 2; }
.parent-4 .div6 { grid-area: 2 / 2 / 3 / 3; }
.parent-4 .div7 { grid-area: 2 / 3 / 3 / 4; }
.parent-4 .div8 { grid-area: 2 / 4 / 3 / 5; }
.parent-4 .div9 { grid-area: 3 / 1 / 4 / 2; }
.parent-4 .div10 { grid-area: 3 / 2 / 4 / 3; }
.parent-4 .div11 { grid-area: 3 / 3 / 4 / 4; }
.parent-4 .div12 { grid-area: 3 / 4 / 4 / 5; }
.parent-4 .div13 { grid-area: 4 / 1 / 5 / 2; }
.parent-4 .div14 { grid-area: 4 / 2 / 5 / 3; }
.parent-4 .div15 { grid-area: 4 / 3 / 5 / 4; }
.parent-4 .div16 { grid-area: 4 / 4 / 5 / 5; }

.parent-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.parent-5 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-5 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-5 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-5 .div4 { grid-area: 1 / 4 / 2 / 5; }
.parent-5 .div5 { grid-area: 1 / 5 / 2 / 6; }
.parent-5 .div6 { grid-area: 2 / 1 / 3 / 2; }
.parent-5 .div7 { grid-area: 2 / 2 / 3 / 3; }
.parent-5 .div8 { grid-area: 2 / 3 / 3 / 4; }
.parent-5 .div9 { grid-area: 2 / 4 / 3 / 5; }
.parent-5 .div10 { grid-area: 2 / 5 / 3 / 6; }
.parent-5 .div11 { grid-area: 3 / 1 / 4 / 2; }
.parent-5 .div12 { grid-area: 3 / 2 / 4 / 3; }
.parent-5 .div13 { grid-area: 3 / 3 / 4 / 4; }
.parent-5 .div14 { grid-area: 3 / 4 / 4 / 5; }
.parent-5 .div15 { grid-area: 3 / 5 / 4 / 6; }
.parent-5 .div16 { grid-area: 4 / 1 / 5 / 2; }
.parent-5 .div17 { grid-area: 4 / 2 / 5 / 3; }
.parent-5 .div18 { grid-area: 4 / 3 / 5 / 4; }
.parent-5 .div19 { grid-area: 4 / 4 / 5 / 5; }
.parent-5 .div20 { grid-area: 4 / 5 / 5 / 6; }
.parent-5 .div21 { grid-area: 5 / 1 / 6 / 2; }
.parent-5 .div22 { grid-area: 5 / 2 / 6 / 3; }
.parent-5 .div23 { grid-area: 5 / 3 / 6 / 4; }
.parent-5 .div24 { grid-area: 5 / 4 / 6 / 5; }
.parent-5 .div25 { grid-area: 5 / 5 / 6 / 6; }

.parent-6 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}
.parent-6 .div1 { grid-area: 1 / 1 / 3 / 3; }
.parent-6 .div2 { grid-area: 1 / 3 / 2 / 4; }
.parent-6 .div3 { grid-area: 2 / 3 / 3 / 4; }
.parent-6 .div4 { grid-area: 3 / 3 / 4 / 4; }
.parent-6 .div5 { grid-area: 3 / 2 / 4 / 3; }
.parent-6 .div6 { grid-area: 3 / 1 / 4 / 2; }

.parent-7 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-7 .div1 { grid-area: 1 / 1 / 4 / 4; }
.parent-7 .div2 { grid-area: 1 / 4 / 2 / 5; }
.parent-7 .div3 { grid-area: 2 / 4 / 3 / 5; }
.parent-7 .div4 { grid-area: 3 / 4 / 4 / 5; }
.parent-7 .div5 { grid-area: 4 / 4 / 5 / 5; }
.parent-7 .div6 { grid-area: 4 / 3 / 5 / 4; }
.parent-7 .div7 { grid-area: 4 / 2 / 5 / 3; }
.parent-7 .div8 { grid-area: 4 / 1 / 5 / 2; }

.parent-8 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}


.parent-8 .div1 { grid-area: 1 / 1 / 5 / 5; }
.parent-8 .div2 { grid-area: 1 / 5 / 2 / 6; }
.parent-8 .div3 { grid-area: 2 / 5 / 3 / 6; }
.parent-8 .div4 { grid-area: 3 / 5 / 4 / 6; }
.parent-8 .div5 { grid-area: 4 / 5 / 5 / 6; }
.parent-8 .div6 { grid-area: 5 / 5 / 6 / 6; }
.parent-8 .div7 { grid-area: 5 / 4 / 6 / 5; }
.parent-8 .div8 { grid-area: 5 / 3 / 6 / 4; }
.parent-8 .div9 { grid-area: 5 / 2 / 6 / 3; }
.parent-8 .div10 { grid-area: 5 / 1 / 6 / 2; }

.parent-9 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-9 .div1 { grid-area: 2 / 2 / 4 / 4; }
.parent-9 .div2 { grid-area: 1 / 1 / 2 / 2; }
.parent-9 .div3 { grid-area: 1 / 2 / 2 / 3; }
.parent-9 .div4 { grid-area: 1 / 3 / 2 / 4; }
.parent-9 .div5 { grid-area: 1 / 4 / 2 / 5; }
.parent-9 .div6 { grid-area: 2 / 4 / 3 / 5; }
.parent-9 .div7 { grid-area: 3 / 4 / 4 / 5; }
.parent-9 .div8 { grid-area: 4 / 4 / 5 / 5; }
.parent-9 .div9 { grid-area: 4 / 3 / 5 / 4; }
.parent-9 .div10 { grid-area: 4 / 2 / 5 / 3; }
.parent-9 .div11 { grid-area: 4 / 1 / 5 / 2; }
.parent-9 .div12 { grid-area: 3 / 1 / 4 / 2; }
.parent-9 .div13 { grid-area: 2 / 1 / 3 / 2; }

.parent-10 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-10 .div1 { grid-area: 2 / 2 / 5 / 5; }
.parent-10 .div2 { grid-area: 1 / 1 / 2 / 2; }
.parent-10 .div3 { grid-area: 1 / 2 / 2 / 3; }
.parent-10 .div4 { grid-area: 1 / 3 / 2 / 4; }
.parent-10 .div5 { grid-area: 1 / 4 / 2 / 5; }
.parent-10 .div6 { grid-area: 1 / 5 / 2 / 6; }
.parent-10 .div7 { grid-area: 2 / 5 / 3 / 6; }
.parent-10 .div8 { grid-area: 3 / 5 / 4 / 6; }
.parent-10 .div9 { grid-area: 4 / 5 / 5 / 6; }
.parent-10 .div10 { grid-area: 5 / 5 / 6 / 6; }
.parent-10 .div11 { grid-area: 5 / 4 / 6 / 5; }
.parent-10 .div12 { grid-area: 5 / 3 / 6 / 4; }
.parent-10 .div13 { grid-area: 5 / 2 / 6 / 3; }
.parent-10 .div14 { grid-area: 5 / 1 / 6 / 2; }
.parent-10 .div15 { grid-area: 4 / 1 / 5 / 2; }
.parent-10 .div16 { grid-area: 3 / 1 / 4 / 2; }
.parent-10 .div17 { grid-area: 2 / 1 / 3 / 2; }

.parent-11 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-11 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-11 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-11 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-11 .div4 { grid-area: 2 / 1 / 3 / 2; }
.parent-11 .div5 { grid-area: 2 / 2 / 3 / 3; }
.parent-11 .div6 { grid-area: 2 / 3 / 3 / 4; }

.parent-12 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-12 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-12 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-12 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-12 .div4 { grid-area: 1 / 4 / 2 / 5; }
.parent-12 .div5 { grid-area: 2 / 1 / 3 / 2; }
.parent-12 .div6 { grid-area: 2 / 2 / 3 / 3; }
.parent-12 .div7 { grid-area: 2 / 3 / 3 / 4; }
.parent-12 .div8 { grid-area: 2 / 4 / 3 / 5; }
.parent-12 .div9 { grid-area: 3 / 1 / 4 / 2; }
.parent-12 .div10 { grid-area: 3 / 2 / 4 / 3; }
.parent-12 .div11 { grid-area: 3 / 3 / 4 / 4; }
.parent-12 .div12 { grid-area: 3 / 4 / 4 / 5; }

.parent-13 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-13 .div1 { grid-area: 1 / 1 / 2 / 2; }
.parent-13 .div2 { grid-area: 1 / 2 / 2 / 3; }
.parent-13 .div3 { grid-area: 1 / 3 / 2 / 4; }
.parent-13 .div4 { grid-area: 1 / 4 / 2 / 5; }
.parent-13 .div5 { grid-area: 1 / 5 / 2 / 6; }
.parent-13 .div6 { grid-area: 1 / 6 / 2 / 7; }
.parent-13 .div7 { grid-area: 2 / 1 / 3 / 2; }
.parent-13 .div8 { grid-area: 2 / 2 / 3 / 3; }
.parent-13 .div9 { grid-area: 2 / 3 / 3 / 4; }
.parent-13 .div10 { grid-area: 2 / 4 / 3 / 5; }
.parent-13 .div11 { grid-area: 2 / 5 / 3 / 6; }
.parent-13 .div12 { grid-area: 2 / 6 / 3 / 7; }
.parent-13 .div13 { grid-area: 3 / 1 / 4 / 2; }
.parent-13 .div14 { grid-area: 3 / 2 / 4 / 3; }
.parent-13 .div15 { grid-area: 3 / 3 / 4 / 4; }
.parent-13 .div16 { grid-area: 3 / 4 / 4 / 5; }
.parent-13 .div17 { grid-area: 3 / 5 / 4 / 6; }
.parent-13 .div18 { grid-area: 3 / 6 / 4 / 7; }
.parent-13 .div19 { grid-area: 4 / 1 / 5 / 2; }
.parent-13 .div20 { grid-area: 4 / 2 / 5 / 3; }
.parent-13 .div21 { grid-area: 4 / 3 / 5 / 4; }
.parent-13 .div22 { grid-area: 4 / 4 / 5 / 5; }
.parent-13 .div23 { grid-area: 4 / 5 / 5 / 6; }
.parent-13 .div24 { grid-area: 4 / 6 / 5 / 7; }

.parent-14 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-14 .div1 { grid-area: 1 / 2 / 3 / 4; }
.parent-14 .div2 { grid-area: 1 / 1 / 2 / 2; }
.parent-14 .div3 { grid-area: 2 / 1 / 3 / 2; }
.parent-14 .div4 { grid-area: 3 / 1 / 4 / 2; }
.parent-14 .div5 { grid-area: 3 / 2 / 4 / 3; }
.parent-14 .div6 { grid-area: 3 / 3 / 4 / 4; }
.parent-14 .div7 { grid-area: 3 / 4 / 4 / 5; }
.parent-14 .div8 { grid-area: 2 / 4 / 3 / 5; }
.parent-14 .div9 { grid-area: 1 / 4 / 2 / 5; }

.parent-15 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-15 .div1 { grid-area: 1 / 1 / 3 / 3; }
.parent-15 .div2 { grid-area: 1 / 3 / 2 / 4; }
.parent-15 .div3 { grid-area: 1 / 4 / 2 / 5; }
.parent-15 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-15 .div5 { grid-area: 2 / 4 / 3 / 5; }
.parent-15 .div6 { grid-area: 3 / 1 / 4 / 2; }
.parent-15 .div7 { grid-area: 3 / 2 / 4 / 3; }
.parent-15 .div8 { grid-area: 3 / 3 / 4 / 4; }
.parent-15 .div9 { grid-area: 3 / 4 / 4 / 5; }

.parent-16 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-16 .div1 { grid-area: 1 / 1 / 2 / 4; }
.parent-16 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-16 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-16 .div4 { grid-area: 2 / 3 / 3 / 4; }

.parent-17 {
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-column-gap: 4px;
grid-row-gap: 4px;
}

.parent-17 .div1 { grid-area: 1 / 1 / 2 / 4; }
.parent-17 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-17 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-17 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-17 .div5 { grid-area: 3 / 1 / 4 / 2; }
.parent-17 .div6 { grid-area: 3 / 2 / 4 / 3; }
.parent-17 .div7 { grid-area: 3 / 3 / 4 / 4; }

.parent-18 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-18 .div1 { grid-area: 1 / 1 / 2 / 5; }
.parent-18 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-18 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-18 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-18 .div5 { grid-area: 2 / 4 / 3 / 5; }
.parent-18 .div6 { grid-area: 3 / 1 / 4 / 2; }
.parent-18 .div7 { grid-area: 3 / 2 / 4 / 3; }
.parent-18 .div8 { grid-area: 3 / 3 / 4 / 4; }
.parent-18 .div9 { grid-area: 3 / 4 / 4 / 5; }

.parent-19 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-19 .div1 { grid-area: 1 / 1 / 2 / 5; }
.parent-19 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-19 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-19 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-19 .div5 { grid-area: 2 / 4 / 3 / 5; }
.parent-19 .div6 { grid-area: 3 / 1 / 4 / 2; }
.parent-19 .div7 { grid-area: 3 / 2 / 4 / 3; }
.parent-19 .div8 { grid-area: 3 / 3 / 4 / 4; }
.parent-19 .div9 { grid-area: 3 / 4 / 4 / 5; }
.parent-19 .div10 { grid-area: 4 / 1 / 5 / 2; }
.parent-19 .div11 { grid-area: 4 / 2 / 5 / 3; }
.parent-19 .div12 { grid-area: 4 / 3 / 5 / 4; }
.parent-19 .div13 { grid-area: 4 / 4 / 5 / 5; }

.parent-20 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
}

.parent-20 .div1 { grid-area: 1 / 1 / 2 / 6; }
.parent-20 .div2 { grid-area: 2 / 1 / 3 / 2; }
.parent-20 .div3 { grid-area: 2 / 2 / 3 / 3; }
.parent-20 .div4 { grid-area: 2 / 3 / 3 / 4; }
.parent-20 .div5 { grid-area: 2 / 4 / 3 / 5; }
.parent-20 .div6 { grid-area: 2 / 5 / 3 / 6; }
.parent-20 .div7 { grid-area: 3 / 1 / 4 / 2; }
.parent-20 .div8 { grid-area: 3 / 2 / 4 / 3; }
.parent-20 .div9 { grid-area: 3 / 3 / 4 / 4; }
.parent-20 .div10 { grid-area: 3 / 4 / 4 / 5; }
.parent-20 .div11 { grid-area: 3 / 5 / 4 / 6; }
.parent-20 .div12 { grid-area: 4 / 1 / 5 / 2; }
.parent-20 .div13 { grid-area: 4 / 2 / 5 / 3; }
.parent-20 .div14 { grid-area: 4 / 3 / 5 / 4; }
.parent-20 .div15 { grid-area: 4 / 4 / 5 / 5; }
.parent-20 .div16 { grid-area: 4 / 5 / 5 / 6; }
.parent-20 .div17 { grid-area: 5 / 1 / 6 / 2; }
.parent-20 .div18 { grid-area: 5 / 2 / 6 / 3; }
.parent-20 .div19 { grid-area: 5 / 3 / 6 / 4; }
.parent-20 .div20 { grid-area: 5 / 4 / 6 / 5; }
.parent-20 .div21 { grid-area: 5 / 5 / 6 / 6; }

.list-group-item {
  border-radius: 4px
}

.vue-grid-layout {
    background: #fff;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #e9ecef;
    border: 1px solid black;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}


</style>